import React, { Component } from 'react'
import { array, shape } from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

class BlogRoll extends Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark
    console.log(posts)
    return (
      <ul className="flex flex-wrap list">
        {posts &&
          posts.map(({ node: post }) => (
            <li key={post.fields.slug} className="pa3 w-100 w-third-ns flex flex-wrap flex-column-ns">
              <div className="flex-auto flex-column flex">
                {post.frontmatter.featuredimage && (
                  <Link to={post.fields.slug}>
                    <div className="aspect-ratio aspect-ratio--16x9 w-100 br3 br--top overflow-hidden">
                      <div className="aspect-ratio--object cover br3 br--top">
                        <BackgroundImage
                          Tag="div"
                          className="aspect-ratio--object cover"
                          fluid={post.frontmatter.featuredimage.childImageSharp.fluid}
                        />
                      </div>
                    </div>
                  </Link>
                )}
                <div className="bg-near-white br3 br--bottom ph2 pv3 relative h-100">
                  {/* <div className="absolute nt4 br2 f7 lh-solid bg-yellow black ph2 pv1 b ttu">Sport</div> */}
                  <div className="">
                    <Link to={post.fields.slug} className="black lh-title mb3">
                      {post.frontmatter.title}
                    </Link>
                  </div>
                  <div className="f7 mt2 dark-gray">
                    <div className="post-date">{post.frontmatter.date}</div>
                  </div>
                </div>
              </div>
            </li>
          ))
        }
      </ul>
    )
  }
}

BlogRoll.propTypes = {
  data: shape({
    allMarkdownRemark: shape({
      edges: array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 400, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)
